<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="SAS_KIND_CD"
            label="구분"
            itemText="codeName"
            itemValue="code"
            name="safetyKindCd"
            type="search"
            v-model="searchParam.safetyKindCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="SAS_TYPE_CD"
            label="보호구종류"
            itemText="codeName"
            itemValue="code"
            name="safetyTypeCd"
            type="search"
            v-model="searchParam.safetyTypeCd">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="보호구 목록"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        :isExcelDown="false"
        :isExpan="false"
        :isFullScreen="false"
        :columnSetting="false"
        gridHeight="500px"
        rowKey="safetyGearId"
        :selection="popupParam.type"
        :editable="editable"
        @rowDblclick="rowDblclick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLSEARCH" icon="search"  @btnClicked="getList"/>
            <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'safetyKindName',
            field: 'safetyKindName',
            label: '보호구구분',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            setHeader: true,
          },
          {
            name: 'safetyTypeName',
            field: 'safetyTypeName',
            label: '보호구종류',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            setHeader: true,
          },
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'supplyVendorName',
            field: 'supplyVendorName',
            label: '공급업체명',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          // {
          //   name: 'price',
          //   field: 'price',
          //   label: '단가(원)',
          //   align: 'right',
          //   type: 'cost',
          //   style: 'width:100px',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        safetyKindCd: null,
        safetyTypeCd: null,
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.listUrl = selectConfig.sop.sas.safetyGear.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },  
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '보호구를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
